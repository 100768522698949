const { serverUrl } = require("../configs");
const KWH = "kWh";
const MWH = "MWh";
const M3 = "m3";

module.exports.NEWS_BODY_LEAD = "NEWS_BODY_LEAD";
module.exports.NEWS_SIDEBAR_LEAD = "NEWS_SIDEBAR_LEAD";

module.exports.PAGE_HP = "PAGE_HP";
module.exports.PAGE_VYPOCET_USPORY_PLYN = "PAGE_VYPOCET_USPORY_PLYN";
module.exports.PAGE_NEWS = "PAGE_NEWS";
module.exports.PAGE_OMNIS = "PAGE_OMNIS";

// TODO: MND bude potrebovat ruzny tarify, tak by bylo dobre tohle konfigurovat
module.exports.MND_SECONDARY_TARIFF_ONLINE = "mndSecondaryOnline";
module.exports.MND_BASIC_TARIFF_ONLINE = "mndBasicOnline";
module.exports.NEWS_CONTENT_CALCULATOR = "NEWS_CONTENT_CALCULATOR";

module.exports.KWH = KWH;
module.exports.MWH = MWH;
module.exports.M3 = M3;

module.exports.unitsOptionsGas = [
  { value: KWH, label: "kWh" },
  { value: MWH, label: "MWh" },
  { value: M3, label: "m3" },
];

module.exports.usesOptionsGas = [
  { value: "cooking", label: "Vaření" },
  { value: "hotwater", label: "Ohřevu vody" },
  { value: "heating", label: "Vytápění" },
];

module.exports.usesOptionsElectricity = [
  { value: "lighting", label: "Svícení" },
  { value: "cooking", label: "Vaření" },
  { value: "hotwater", label: "Ohřevu vody" },
  { value: "heating", label: "Vytápění" },
  { value: "heatpump", label: "Tepelnému čerpadlu" },
];

// defaultni tarif pro odhadovaci formulare pro elektrinu
module.exports.DEFAULT_TARIFF = 3;

// defaultni jistic pro odhadovaci formulare pro elektrinu
module.exports.DEFAULT_CIRCUIT_BREAKER = "4";

// defaultni jistic pro odhadovaci formulare pro elektrinu
module.exports.DEFAULT_PHASE = "3";

// defaultni jistic pro odhadovaci formulare pro elektrinu
module.exports.DEFAULT_PHASE_ONE = "1";

// defaultni jistic pro odhadovaci formulare pro elektrinu
module.exports.DEFAULT_TARIFF_CIRCUIT_BREAKERS_ID = "4";

// defaultl pro maloodberatele
module.exports.DEFAULT_TARIFF_CIRCUIT_BREAKERS_SME_ID = 14;

module.exports.DEFAULT_RATE_ID = 1;

module.exports.ONLINE_SAVINGS_THRESHOLD_UPPER = 63; //MWH
module.exports.ONLINE_SAVINGS_THRESHOLD = 7.56; //MWH

module.exports.MIN_ADVANCE = 150;
module.exports.MAX_ADVANCE = 7000;
module.exports.MIN_CONSUMPTION = 1;
module.exports.MAX_CONSUMPTION = 63;

module.exports.MIN_ELE_ADVANCE = 200;
module.exports.MAX_ELE_ADVANCE = 5950;
module.exports.DEFAULT_ELE_ADVANCE = 1100;
module.exports.DEFAULT_ELE_CONSUMPTION = 1.9;
module.exports.DEFAULT_ELE_RATE = 1;

module.exports.DEFAULT_ADVANCE = 2700;
module.exports.DEFAULT_CONSUMPTION = 21;

module.exports.DEFAULT_HIGHT_CONSUMPTION = 2;
module.exports.DEFAULT_LOW_CONSUMPTION = 0;

module.exports.GAS = "gas";
module.exports.ELECTRICITY = "electricity";
module.exports.ELECTRICITY_API_CODE = "ele";

module.exports.DEFAULT_CITY = "Všenory";
module.exports.DEFAULT_ZIP = "25231";
module.exports.DEFAULT_REGION = "14";
module.exports.CONTRACT_SOURCE_ID = 273;
module.exports.CONTRACT_SOURCE_ID_NEWS = 254;
module.exports.CONTRACT_SOURCE_ID_INTERESTED_IN_OFFER = 114;
module.exports.CONTRACT_SOURCE_ID_DUPLEX = 317;
module.exports.CONTRACT_SOURCE_ID_ZAME = 128;

module.exports.OMNIS_STEHOVANI_LEAD_ID = 276;
module.exports.OMNIS_NOVY_ODBER_LEAD_ID = 277;
module.exports.OMNIS_OBNOVENI_ODBERU_LEAD_ID = 278;
module.exports.OMNIS_ZMENA_DODAVATELE_LEAD_ID = 279;
module.exports.OMNIS_UMRTI_LEAD_ID = 280;

module.exports.SOURCE_CALC = 148;

// MND site Links
module.exports.MND_LINKS = {
  hp: "/",
  gas_hp: "/plyn",
  electricity_hp: "/elektrina",
  switch_to_mnd: "/prepis-energii-elektriny-plynu",
  support: `${serverUrl}/caste-dotazy`,
  contact: "/kontakt",
  search: `${serverUrl}/vyhledavani`,
  documents: `${serverUrl}/dokumenty-ke-stazeni`,
  about: `${serverUrl}/o-nas`,
  partners: `${serverUrl}/nasi-partneri`,
  recipes: `${serverUrl}/recepty`,
  gdpr: `${serverUrl}/osobniudaje`,
  cookies: "/cookies",
  facebook: "https://www.facebook.com/MNDcz/",
  reviewsHP: "/facebook-hp",
  reviews: `${serverUrl}/recenze-nasich-zakazniku`,

  business: `${serverUrl}/velkoodberatele`,
  business_about: `${serverUrl}/velkoodberatele/o-nas`,
  business_contact: `${serverUrl}/velkoodberatele/kontakt`,

  mnd_group: "https://www.mnd.eu/",
  mnd_group_career: "https://www.mnd.eu/kariera/",
  mnd_screen_share: "http://smlouva.mnd.cz/",
  mnd_phone: "800400500",
  mnd_mail: "mnd@mnd.cz",
  mnd_moje: "https://moje.mnd.cz/",

  fb: "https://www.facebook.com/MNDcz",
  youtube: "https://www.youtube.com/user/MNDcz/",
  linked_in: "https://www.linkedin.com/company/mnd-a-s-",
  review: "https://www.facebook.com/MNDcz/posts/4284894308205725",
  news: "novinky",
  faq: `${serverUrl}/caste-dotazy/nejcastejsi-dotazy-spojene-s-rustem-energii`,
  whistleblowing: `${serverUrl}/ochrana-oznamovatelu`,
};

module.exports.CONTENT = {
  phone: {
    formatted: "800 400 500",
    formattedPrefix: "+420 800 400 500",
    number: "800400500",
    href: "+420800400500",
  },
  foreginPhone: {
    formatted: "226 400 500.",
    formattedPrefix: "+420 226 400 500",
    number: "226400500.",
    href: "+420226400500",
  },
  email: {
    formatted: "mnd@mnd.cz",
    href: "mnd@mnd.cz",
  },
};

module.exports.OF_FIRST_STEP_SLUG = "1-zakladni-udaje";
module.exports.BONUS_OF_FIRST_STEP_ADDITIONAL_PARAMS = "&marketingSourceId=317&userGroupId=61"

module.exports.OMNIS_ZMENA_DODAVATELE_SLUG = "zmena-dodavatele";
module.exports.OMNIS_STEHOVANI_SLUG = "stehovani";
module.exports.OMNIS_OBNOVENI_ODBERU_SLUG = "obnoveni-odberu";
module.exports.OMNIS_NOVY_ODBER_SLUG = "novy-odber";
module.exports.OMNIS_UMRTI_SLUG = "dedictvi-umrti";

module.exports.DPI_HP = "DPI_HP";
module.exports.FOOTER_LITE = "FOOTER_LITE";
module.exports.FOOTER_FULL = "FOOTER_FULL";
